import { LoggedUser } from '@core/model/auth/logged-user.model';
import { LoggedUserService } from '@core/service/logged-user.service';
import { FlamingoAuthService } from '@flamingo/service/flamingo-auth.service';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export function initializeApp(authService: FlamingoAuthService, userService: LoggedUserService): () => Observable<any> {
  return () => {
    let ret$: Observable<LoggedUser| null> = of(null);
    if (authService.hasAuthorizationToken) {
      ret$ = userService.fetch();
    } else {
      authService.logout();
    }
    return ret$
      .pipe(
        tap(_ => console.log('Startup App')),
        catchError(_ => {
          authService.logout();
          return EMPTY;
        }),
      );
  };
}
