import { registerLocaleData } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import LocaleIt from '@angular/common/locales/it';
import {
  ApplicationConfig,
  importProvidersFrom,
  inject,
  LOCALE_ID,
  provideAppInitializer,
  provideZoneChangeDetection,
} from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { LoggedUserService } from '@core/service/logged-user.service';
import { FlamingoModule } from '@flamingo/flamingo.module';
import { FlamingoAuthService } from '@flamingo/service/flamingo-auth.service';
import { environment } from '../environments/environment';
import { it } from 'date-fns/locale';
import { routes } from './app.routes';
import { initializeApp } from './app.startup';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {DateFnsAdapter} from '@angular/material-date-fns-adapter';

registerLocaleData(LocaleIt, 'it');

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withComponentInputBinding(),
    ),
    provideAnimationsAsync(),
    provideHttpClient(
      // TODO
      // withInterceptors()
    ),
    importProvidersFrom(FlamingoModule.forRoot({
      storagePrefix: 'italiaalimentari-bo-',
      baseUrl: environment.baseUrl,
    })),
    provideAppInitializer(() => {
      const initializerFn = (initializeApp)(inject(FlamingoAuthService), inject(LoggedUserService));
      return initializerFn();
    }),
    { provide: LOCALE_ID, useValue: 'it-IT' },
    { provide: MAT_DATE_LOCALE, useValue: it },
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { disableTooltipInteractivity: true } },
  ],
};
