import { Routes } from '@angular/router';
import { LoggedGuard } from '@core/guard/logged.guard';
import { NotLoggedGuard } from '@core/guard/not-logged.guard';

export const routes: Routes = [
  {
    path: 'auth',
    canActivate: [NotLoggedGuard],
    loadChildren: () => import('./children/auth/auth.module').then((m) => m.AuthModule)
  },
  // Main
  {
    path: '',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./children/main/main.module').then((m) => m.MainModule)
  },
  // Wild card
  {
    path: '**',
    redirectTo: 'auth'
  }
];
