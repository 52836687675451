import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, ModuleWithProviders, NgModule, Optional, provideAppInitializer, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FlamingoAlertDialogComponent } from './component/flamingo-alert-dialog.component';
import { FlamingoConfirmDialogComponent } from './component/flamingo-confirm-dialog.component';
import { FlamingoInputDialogComponent } from './component/flamingo-input-dialog.component';
import { FLAMINGO_APP_CONFIG, FlamingoAppConfig } from './flamingo.config';
import { FlamingoAuthGuard } from './service/flamingo-auth.guard';
import { FlamingoAuthService } from './service/flamingo-auth.service';
import { FlamingoErrorHandler } from './service/flamingo-error.handler';
import { FlamingoHttpInterceptor } from './service/flamingo-http.interceptor';
import { FlamingoMessageService } from './service/flamingo-message.service';
import { initializeFlamingo } from './service/flamingo.startup';

@NgModule({
  declarations: [
    FlamingoAlertDialogComponent,
    FlamingoInputDialogComponent,
    FlamingoConfirmDialogComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    // HttpClientModule,
    // Mat
    MatInputModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatDialogModule,
    MatButtonModule,
  ],
  exports: [
    FlamingoInputDialogComponent,
    FlamingoConfirmDialogComponent,
  ],
})
export class FlamingoModule {
  constructor(@Optional() @SkipSelf() parentModule: FlamingoModule) {
    if (parentModule) {
      throw new Error('FlamingoModule has already been imported.');
    }
  }

  static forRoot(config?: FlamingoAppConfig): ModuleWithProviders<FlamingoModule> {
    return {
      ngModule: FlamingoModule,
      providers: [
        provideHttpClient(
          withInterceptorsFromDi(),
        ),
        { provide: FLAMINGO_APP_CONFIG, useValue: config },
        provideAppInitializer(() => {
          const initializerFn = (initializeFlamingo)();
          return initializerFn();
        }),
        { provide: ErrorHandler, useClass: FlamingoErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: FlamingoHttpInterceptor, multi: true },
        FlamingoAuthService,
        FlamingoMessageService,
        FlamingoAuthGuard,
      ],
    };
  }
}
