import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize, first, switchMap, tap } from 'rxjs/operators';
import { FlamingoAuthService, TokenType } from './flamingo-auth.service';

@Injectable()
export class FlamingoHttpInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshToken$ = new Subject<void>();

  constructor(private inj: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.inj.get(FlamingoAuthService);

    let newReq = req;
    if (authService.authorizationToken) {
      newReq = this.addTokenHeader(req, authService.authorizationToken);
    }

    return next.handle(newReq)
      .pipe(
        catchError((error: HttpErrorResponse) => {
            if (error.status === 401 && authService.hasAuthorizationToken && !newReq.url.includes('auth/login') && !newReq.url.includes('auth/access_token')) {
              return this.handle401Error(newReq, next, authService);
            }
            return throwError(() => error);
          },
        ));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, authService: FlamingoAuthService): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      return authService.accessToken()
        .pipe(
          finalize(() => this.isRefreshing = false),
          tap(token => authService.setAuthorizationToken(token.access_token, TokenType.bearer),
          ),
          tap(token => this.refreshToken$.next(token.access_token)),
          switchMap(_ => next.handle(this.addTokenHeader(request, authService.authorizationToken!))),
          catchError(error => {
            if (error.status === 401) {
              authService.logout();
              document.location.reload();
              return EMPTY;
            }

            return throwError(() => error);
          }),
        );
    }

    return this.refreshToken$
      .pipe(
        first(),
        switchMap(_ => next.handle(this.addTokenHeader(request, authService.authorizationToken!))),
      );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set('Authorization', token) });
  }
}
